import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import queryString from "query-string";
import toastr from "src/components/Common/toastr";
import GitHubLogo from "../assets/images/brands/github.svg";
import ThreeDots from "../assets/images/threedots.svg";
import { AppAnalytics } from "../helpers/analytics_helper";
import { verifyGithubAuth } from "../helpers/backend_helper";
import history from "../helpers/history";
import { createGetURL, isIntegrationFlow } from "../helpers/utils";
import { apiError } from "../store/actions";
import { getUtmParams } from "../utils";

const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;

class GithubLoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOption: false,
      authParams: ["code", "state"],
      region: "",
    };
    this.handleOptions = this.handleOptions.bind(this);
  }

  componentDidMount() {
    if (
      new URLSearchParams(window.location.search).has("code") &&
      new URLSearchParams(window.location.search).has("state") &&
      this.props.handleSetViewSpinner
    ) {
      this.props.handleSetViewSpinner();
    }
    const { apiError, loginUser } = this.props;
    const searchParams = queryString.parse(window.location.search);
    if (
      searchParams.code &&
      !searchParams.configurationId &&
      searchParams.resourceType !== "ws"
    ) {
      try {
        const stateParams = JSON.parse(decodeURIComponent(searchParams.state));
        const currentRegion = stateParams?.region || "in";
        const apiRedirect = () => {
          if (stateParams["redirect_url"]) {
            setTimeout(() => {
              window.location.href = stateParams["redirect_url"];
            }, 100);
          } else if (isIntegrationFlow()) {
            const redirectURI = createGetURL(
              window.location.origin + window.location.pathname,
              { ...stateParams }
            );
            setTimeout(() => {
              window.location.href = redirectURI;
            }, 350);
          } else {
            history.push({
              pathname: "/dashboard",
            });
          }
        };

        verifyGithubAuth(
          {
            code: searchParams.code,
            currentRegion: currentRegion,
          },
          { loginUser, apiError }
        )
          .then(() => {
            apiRedirect();
          })
          .catch(e => {
            toastr.error(e.response.data.msg);
            apiRedirect();
          });
      } catch (error) {
        console.error("Github Authentication Failure!", error.message);
        AppAnalytics.track("login.oauth.request.failure", {
          id_token: searchParams.data,
          msg: JSON.stringify(error.message),
        });
        apiError("Github Authentication Failed! Please try again");
      }
    }
  }

  DummyView = () => {
    const region = useSelector(state => state.userInfo.region);
    useEffect(() => {
      this.setState({
        region: region,
      });
    }, [region]);
    return <></>;
  };

  handleGithubLogin() {
    const baseURL = "https://github.com/login/oauth/authorize?";
    const params = {
      client_id: clientId,
      scope: "read:user,user:email",
      redirect_uri: window.location.origin + window.location.pathname,
      state: encodeURIComponent(
        JSON.stringify({
          ...getUtmParams(),
          ...queryString.parse(window.location?.search),
          region: this.state.region,
        })
      ),
    };

    window.location.href = createGetURL(baseURL, params);
  }
  handleOptions() {
    this.setState({ showOption: !this.state.showOption });
  }

  render() {
    const { buttonText } = this.props;
    const { options } = this.props;
    return (
      <div
        className={`flex flex-row text-gray-900 font-medium text-1.0625 hover:text-gray-900 focus:text-gray-900 focus:ring-2 focus:ring-blue-500 w-full rounded-md items-center relative ${
          this.state.region !== ""
            ? " github-button"
            : " github-button-disabled"
        }`}
        onBlur={event => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            this.setState({ ...this.state, showOption: false });
          }
        }}
      >
        <this.DummyView />
        <button
          disabled={this.state.region === ""}
          className={`btn  text-white-100 justify-center font-medium text-1.0625 hover:text-white-100 focus:text-white-100  flex-row flex items-center ${
            options ? `w-11/12 ` : `w-full`
          }`}
          onClick={() => {
            this.handleGithubLogin();
          }}
        >
          <img
            className={`h-6 w-6 ${options ? `mr-2` : `mr-2`}`}
            alt="github logo"
            src={GitHubLogo}
          />
          <p className="mb-0">{buttonText || "Continue with Github"}</p>
        </button>
        {options && (
          <button
            className={`items-center ${options ? `w-1/12` : `w-0`}`}
            onClick={() => {
              this.handleOptions();
            }}
          >
            <img className=" h-6 w-6" alt="google logo" src={ThreeDots} />
          </button>
        )}
        {this.state.showOption && (
          <div className="z-20 bg-gray-cool2 top-16 right-0  w-max rounded absolute text-center text-white px-4 py-2 shadow">
            {options.map((o, i) => (
              <button
                className="flex text-center pt-1"
                onClick={o.handleShowFields}
                key={`${i}${i * i}`}
              >
                {o.message}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStatetoProps = state => {
  const { error } = state.Login;
  return { error };
};

export default connect(mapStatetoProps, { apiError })(GithubLoginButton);
