import _, { capitalize, isEmpty } from "lodash";
import moment from "moment";
import { invalidEmails } from "./invalidEmailsList";
import audioRoom from "../assets/images/app-builder/app-builder-audio-room.svg";
import createOwn from "../assets/images/app-builder/app-builder-create-own.svg";
import edtech from "../assets/images/app-builder/app-builder-edtech.svg";
import eventApp from "../assets/images/app-builder/app-builder-event-app.svg";
import videoConf from "../assets/images/app-builder/app-builder-video-conf.svg";
import { AppType, ASSET_TYPES, RECORDING_TYPES } from "../constants";
import { convertRole } from "../services/cmsModels/Policy/helpers/index";
import {
  calculateAspectRatio,
  calculateLayerQuality,
  currentUser,
  getUtmParams,
} from "../utils";

export const copyToClipboard = text => {
  try {
    navigator.clipboard.writeText(text);
  } catch (e) {
    console.error(e);
  }
};

export const createGetURL = (baseURL, params = {}) => {
  const url = new URL(baseURL);
  _.forEach(params, (value, key) => {
    url.searchParams.set(key, value);
  });
  return url.href;
};

export function convertSecondsToHumanReadable(seconds) {
  if (seconds === 0 || !seconds) {
    return "0 seconds";
  }

  const duration = moment.duration(seconds, "seconds");
  return duration.format("d [days] h [hrs] m [mins] s [seconds]");
}

export const getURL = (baseURL, params = {}) => {
  return createGetURL(baseURL, { ...getUtmParams(), ...params });
};

export const splitName = name => {
  const nameArray = name.trim().split(" ");
  return {
    firstName: nameArray[0],
    lastName: nameArray.slice(1).join(" "),
  };
};

export const isAllowedEmail = (email, discardedEmails = []) => {
  const invalidEmailsList = [...invalidEmails, ...discardedEmails];
  //check whether email is allowed or not
  return !invalidEmailsList.some(invalidEmail =>
    email.includes(`"@"${invalidEmail}`)
  );
};

export const checkPasswordPolicy = (password, name) => {
  let nameCheck = true;
  const { firstName } = splitName(name);
  if (firstName) {
    nameCheck = !password.toLowerCase().includes(firstName.toLowerCase());
  }
  // one uppercase letter
  let pattern = /[A-Z]/g;
  const uppercaseCheck = pattern.test(password);
  // one lowercase letter
  pattern = /[a-z]/g;
  const lowercaseCheck = pattern.test(password);
  // number or special character
  pattern = /([0-9]|[^A-Za-z])/g;
  const numberOrSpecialCheck = pattern.test(password);
  // min 8 char
  const minimumCheck = password.length >= 10;
  return {
    uppercaseCheck,
    lowercaseCheck,
    numberOrSpecialCheck,
    minimumCheck,
    nameCheck,
    isPasswordValid:
      uppercaseCheck &&
      lowercaseCheck &&
      numberOrSpecialCheck &&
      minimumCheck &&
      nameCheck,
  };
};

export const getRolesFromPublicTemplate = template => {
  if (!template) {
    return [];
  }

  return template.attributes.roles.map(convertRole);
};

const getAppImage = (app, publicTemplates) => {
  switch (app?.app_type) {
    case AppType.EDTECH:
      return edtech;
    case AppType.AUDIOROOM:
      return audioRoom;
    case AppType.EVENTAPP:
      return eventApp;
    case AppType.CREATEOWN:
      if (app.ts_template_id) {
        const templateInfo = publicTemplates.find(
          template => template.id === app.ts_template_id
        );

        return _.get(
          templateInfo,
          "attributes.icon.data.attributes.url",
          createOwn
        );
      }
      return createOwn;
    case AppType.VIDEOCONF:
      return videoConf;
    default:
      return createOwn;
  }
};

export function isIntegrationFlow() {
  return (
    window.location.pathname.startsWith(
      "/vercel-template-integration/cms-datocms"
    ) ||
    window.location.pathname.startsWith("/vercel-integration") ||
    window.location.pathname.startsWith("/login")
  );
}

/**
 * Adapter method to fetch template metadata. Attempts to
 * get metadata from CMS info with a fallback to static
 * template meta.
 */
export function getAppInfo(app, publicTemplates) {
  const image = getAppImage(app, publicTemplates);
  let name = "Your Template";

  if (app?.app_type === "custom") {
    name = "Custom Template";
  }

  const template = publicTemplates.find(
    template => template.type === app.app_type
  );

  if (template) {
    name = template.name;
  }
  if (app?.app_type === AppType.CREATEOWN && app.ts_template_id) {
    const publicTemplate = publicTemplates.find(
      template => template.id === app.ts_template_id
    );

    name = `${_.get(publicTemplate, "attributes.name", name)}`;
  }
  return { image, name, appName: app?.app_name };
}

export function buildRoomLink({
  subdomain = "",
  deployment_url = "",
  role = "",
  role_identifier = "",
  deeplinkprefixpath = "",
}) {
  if (deployment_url) {
    return `https://${deployment_url}?role=${role}`;
  }
  let path = "";
  if (deeplinkprefixpath) {
    path += `/${deeplinkprefixpath}`;
  }
  path += `/meeting/${role_identifier}`;
  return `https://${subdomain}${path}`;
}

export const validEmail = value => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(value);
};

export const isCustomPublishParams = (property = "") => {
  return property.toLowerCase() === "custom";
};

export const openDevrevWidget = toggle => {
  if (window?.plugSDK?.isPlugInitialized) {
    window?.plugSDK?.toggleWidget(toggle);
  }
};

export const truncateString = (str, len, endWith = "...") =>
  str?.length > len ? `${str.slice(0, len)}${endWith}` : str;

export const toTitleCase = string => {
  if (string === null || string === undefined) {
    return "";
  }
  const words = string.split(" ");
  const formattedWords = words.map(word => capitalize(word));
  return formattedWords.join(" ");
};

export const getCommaSeparatedStringFromArray = array => {
  return array?.length ? array?.toString().replaceAll(",", ", ") : "";
};

export const getVideoQuality = (width, height) => {
  const layer = calculateLayerQuality(width, height);

  return isEmpty(layer) ? "custom" : calculateAspectRatio({ width, height });
};

export const getRecordingType = recordingAssets => {
  if (recordingAssets.includes(ASSET_TYPES.ROOM_COMPOSITE_LEGACY)) {
    return RECORDING_TYPES.COMPOSITE;
  } else if (
    recordingAssets.includes(ASSET_TYPES.ROOM_COMPOSITE) &&
    recordingAssets.includes(ASSET_TYPES.ROOM_VOD)
  ) {
    return RECORDING_TYPES.HLS;
  } else if (recordingAssets.includes(ASSET_TYPES.ROOM_COMPOSITE)) {
    return RECORDING_TYPES.BROWSER;
  }
  return "-";
};

export const layerMatches = (layer, width, height) => {
  return layer.width === width && layer.height === height;
};

export const isEqualHLSLayer = (layer, width, height, videoBitrate) => {
  return (
    layer.width === width &&
    layer.height === height &&
    (videoBitrate ? layer.videoBitrate === videoBitrate : true)
  );
};

export const getSubsetLayers = (parentArray = [], childArray = []) => {
  const layerSet = new Set();
  const validSubset = [];

  for (const layer of parentArray) {
    layerSet.add(JSON.stringify(layer));
  }

  for (const layer of childArray) {
    if (layerSet.has(JSON.stringify(layer))) {
      validSubset.push(layer);
    }
  }

  return validSubset;
};

export const isPrebuiltAllowed = () => {
  const user = currentUser();
  const email = user?.email;
  if (!email || user?.admin) {
    return false;
  }
  const pattern = new RegExp(
    process.env.REACT_APP_PREBUILT_ALLOWED_EMAIL_REGEX,
    "u"
  );
  return pattern.test(email);
};

export const isInsightsAllowed = () => {
  const user = currentUser();
  const email = user?.email;
  if (!email || user?.admin) {
    return false;
  }
  const pattern = new RegExp(
    process.env.REACT_APP_INSIGHTS_ALLOWED_EMAIL_REGEX,
    "u"
  );
  return pattern.test(email);
};

export const flattenObject = (obj, parentKey = "") => {
  let result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}.${_.snakeCase(key)}` : key;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        // Recursively flatten nested objects
        const nestedObject = flattenObject(obj[key], newKey);
        result = { ...result, ...nestedObject };
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
};

export const convertToBitratePerSecond = (bitrate, delimiter = 0) => {
  // write function to convert bit to byte and append kb mb gb etc
  if (bitrate === 0) {
    return "0 kbps";
  }

  if (bitrate / 1000 ** 3 >= 1) {
    return `${(bitrate / 1000 ** 3).toFixed(delimiter)} tbps`;
  }
  if (bitrate / 1000 ** 2 >= 1) {
    return `${(bitrate / 1000 ** 2).toFixed(delimiter)} gbps`;
  }
  return bitrate / 1000 >= 1
    ? `${(bitrate / 1000).toFixed(delimiter)} mbps`
    : `${bitrate.toFixed(delimiter)} kbps`;
};

export class MathStats {
  constructor() {
    this.asc = this.asc.bind(this);
    this.desc = this.desc.bind(this);
    this.sum = this.sum.bind(this);
    this.mean = this.mean.bind(this);
    this.std = this.std.bind(this);
    this.quantile = this.quantile.bind(this);
  }
  asc = arr => arr.sort((a, b) => a - b);
  desc = arr => arr.sort((a, b) => b - a);
  sum = arr => arr.reduce((a, b) => a + b, 0);
  mean = arr => this.sum(arr) / arr.length;
  std = arr => {
    const mu = this.mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(this.mean(diffArr));
  };
  quantile = (arr, q) => {
    const sorted = this.asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    }
    return sorted[base];
  };
}
