import React from "react";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import appearance from "./stripeAppearanceConfig.json";
import "./common.css";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeWrapper({ children }) {
  const { billing } = useSelector(state => state.organisation);
  const clientSecret = billing?.clientSecret;
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}

export default StripeWrapper;
